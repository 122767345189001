import React from 'react'
import styled from 'styled-components'
import Layout from "../components/Layout/Layout"
import Button from '../components/shared/Button/Button'
import { H3 } from '../components/shared/Typography/Typography'
import Input from '../components/shared/FormComponents/Input'
import Textarea from '../components/shared/FormComponents/Textarea'
import FileInput from '../components/shared/FormComponents/FileInput'

const Row = styled.div`
    display: flex;
`
const Col = styled.div`
    margin: 0 20px;
`
export default function Sandbox () {
  return (
    <Layout>
      <Row>
        <Col>
          <H3>buttons</H3>
          <Button color='primary' >Nasze realizacje </Button>
          <br />
          <Button color='primary' disabled >Nasze realizacje </Button>
          <br />
          <Button color='primary' prefixIcon >Nasze realizacje </Button>
          <br />
          <Button color='primary' sufixIcon >Nasze realizacje </Button>
          <br />
          <Button color='secondary' >Nasze realizacje </Button>
          <br />
          <Button color='secondary' prefixIcon >Nasze realizacje </Button>
          <br />
          <Button color='secondary' sufixIcon >Nasze realizacje </Button>
          <br />
          <Button color='secondary' disabled >Nasze realizacje </Button>
        </Col>
        <Col>
          <H3>Form elements</H3>
          <Input placeholder="Placeholder" name="inputName" type="text" />
          <br />
          <Textarea placeholder="Placeholder" name="inputName" type="text" />
          <br />
          <FileInput name="fileInput" />
        </Col>
      </Row>
    </Layout>
  )
}
